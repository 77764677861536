export const environment = {
  name: 'staging',
  production: false,
  serverUrl: 'https://staging.api.caplock.app',
  lastCompilationTime: '2024-11-19T21:04:59.154Z',
  awsRegion: 'eu-west-3',
  awsPictureBucket: 'staging-caplock-picture',
  awsPublicBucket: 'staging-caplock-public',
  userPoolId: 'eu-west-3_2T3t0v2Lv',
  userPoolWebClientId: '5qbot31juocid56153j6fp9ukg',
  databaseCoherencyUrl:'https://z5mmprhmkk.execute-api.eu-west-3.amazonaws.com/production/database-coherency',
};
